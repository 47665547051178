<template>
    <div class="app">
        <div class="admin_main_block">
            <div class="admin_breadcrumb">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/Admin/index' }"
                    >首页
                    </el-breadcrumb-item>
                    <el-breadcrumb-item>共享商城</el-breadcrumb-item>
                    <el-breadcrumb-item>用户列表</el-breadcrumb-item>
                    <el-breadcrumb-item>绑定关系</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <el-form ref="form" :model="form" :rules="rules">
            <div class="admin_main_block admin_m15">
                <el-row >
                    <el-col :span="span" class="hight">
                        <el-form-item label="升级账号（会员账号）" prop="account" :rules="[
                {
                  required: true,
                  message: '升级账号不能为空',
                  trigger: 'blur',
                },
                {
                  min: 11,
                  max: 11,
                  message: '长度在 11 个字符',
                  trigger: 'blur',
                },
              ]">
                            <el-input
                                    placeholder=""
                                    v-model="form.account"
                                    :disabled="disabled"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="span" class="hight">
                        <el-form-item label="等级">
                            <el-radio-group v-model="form.level">
                                <el-radio :label="1">形象店</el-radio>
                                <el-radio :label="2">旗舰店</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="span" class="hight">
                        <el-form-item
                                label="上级账号（会员账号）"
                                prop="parAccount"
                                :rules="[
                {
                  required: false,
                  message: '请输入手机号码（会员账号）',
                  trigger: 'blur',
                },
                {
                  min: 11,
                  max: 11,
                  message: '长度在 11 个字符',
                  trigger: 'blur',
                },
              ]"
                        >
                            <el-input placeholder="" v-model="form.parAccount" :disabled="disabled" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </div>
            <div class="admin_main_block admin_m15">
                <el-row>
                    <el-col :span="24">
                        <el-button icon="el-icon-check" type="info" @click="back">
                            取消
                        </el-button>
                        <el-button
                                icon="el-icon-back"
                                type="primary"
                                @click="success('form')"
                        >
                            提交
                        </el-button>
                    </el-col>
                </el-row>
            </div>
        </el-form>
    </div>
</template>
<script>

    export default {
        components: {
        },
        props: {},
        data() {
            return {
                rules:[],
                span: 6,
                disabled: false,
                actionUploadUrl: "",
                enable: {
                    true: "开启",
                    false: "关闭",
                },
                isEdit: true,
                form: {
                    level:1,
                    parAccount:'',
                    account: "",
                },
                lock: false
            };
        },
        methods: {
            back() {
                this.$router.go(-1); //返回上一层
            },
            success() {
                this.$confirm("是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        const url = this.$api.shareMall.binding;
                        this.post(url, this.form);
                    })
                    .catch(() => {
                        this.$message({
                            type: "info",
                            message: "已取消",
                        });
                    });
            },
            async post(url, data) {
                await this.$post(url, data).then((res) => {
                    if (res.code === 1) {
                        this.$message({
                            type: "success",
                            message: "成功!",
                        });
                        location.reload();
                    } else {
                        this.$message({
                            type: "error",
                            message: "失败!",
                        });
                    }
                });
            },
            getUserinfo() {
                if (this.$isEmpty(this.form.parAccount)) {
                    return false;
                }
                this.$get(this.$api.getAccount + "/" + this.form.parAccount, {}).then(
                    (res) => {
                        if (res.code === 1) {
                            // this.form.realName = res.data.nickName;
                            // this.form.userId = res.data.id;
                        } else {
                            this.$message({
                                type: "error",
                                message: "账户获取信息不存在!",
                            });
                        }
                    }
                );
            },
        },
        created() {
            // this.infoData();
        },
    };
</script>
<style lang="scss" scoped>
    .is-always-shadow {
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    .is-always-shadow .el-col {
        height: auto;
    }

    .el-card {
        background: #F2F6FC;
        text-align: right;
        margin-top: 30px;
    }

    .el-row {

        &:last-child {
            margin-bottom: 0;
        }
    }


    .el-col {
        border-radius: 4px;
    }

    .el-tab-pane {
        padding-top: 10px;
    }

    .grid-content {
        border-radius: 4px;
        min-height: 36px;
    }

    .el-divider--horizontal {
        margin: 12px 0px 12px 0px;
    }

    .sub-title {
        min-height: 26px;
    }

    .el-imag {
        border: 1px dashed #d9d9d9;
        border-radius: 6px;
        cursor: pointer;
        position: relative;
        overflow: hidden;
        width: 260px;
        height: 178px;
    }

    .el-imag:hover {
        border-color: #409EFF;
    }

    .el-icon-picture-outline {
        font-size: 28px;
        color: #8c939d;
        width: 178px;
        height: 178px;
        line-height: 178px;
        text-align: center;
    }

    .orderStatus {
        font-size: large;
        color: #409EFF;
    }

    .right {
        text-align: center;
        line-height: 30px;
    }

    .admin_table_main_pagination {
        line-height: 40px;
        text-align: right;
    }

    .admin_table_main_pagination .total {
        font-size: 18px;
        font-weight: bold;
        color: #000000;
    }

    .admin_table_main_pagination .number {
        font-size: 18px;
        font-weight: bold;
        color: #ff0000;
    }

    .el-select {
        width: 100%;
    }
</style>

